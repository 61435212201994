import React from 'react';

import { FormattedMessage } from 'react-intl';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import css from './SectionContainer.module.css';

const SectionFrequentlyAsked = () => {
  return (
    <div className={css.SectionFrequentlyAsked}>
      <h1 className={css.title}>
        <FormattedMessage
          id="SectionFrequentlyAsked.title"
          values={{
            HighlitedText: (
              <span className={css.highlitedText}>
                <span>Asked</span>
              </span>
            ),
          }}
        />
      </h1>

      <div className={css.faqsec}>
        <div className={css.faqwrapper}>
          <Accordion allowZeroExpanded={true}>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <div className={css.faqItemCount}>01</div>{' '}
                  <FormattedMessage id="SectionFrequentlyAsked.faqTitle1" />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <FormattedMessage id="SectionFrequentlyAsked.faqDetails1" />
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <div className={css.faqItemCount}>02</div>{' '}
                  <FormattedMessage id="SectionFrequentlyAsked.faqTitle2" />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <FormattedMessage id="SectionFrequentlyAsked.faqDetails2" />
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <div className={css.faqItemCount}>03</div>{' '}
                  <FormattedMessage id="SectionFrequentlyAsked.faqTitle3" />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <FormattedMessage id="SectionFrequentlyAsked.faqDetails3" />
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <div className={css.faqItemCount}>04</div>{' '}
                  <FormattedMessage id="SectionFrequentlyAsked.faqTitle4" />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <FormattedMessage id="SectionFrequentlyAsked.faqDetails4" />
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <div className={css.faqItemCount}>05</div>{' '}
                  <FormattedMessage id="SectionFrequentlyAsked.faqTitle5" />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <FormattedMessage id="SectionFrequentlyAsked.faqDetails5" />
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <div className={css.faqItemCount}>06</div>{' '}
                  <FormattedMessage id="SectionFrequentlyAsked.faqTitle6" />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <FormattedMessage id="SectionFrequentlyAsked.faqDetails6" />
                <ul>
                  <li>
                    <FormattedMessage id="SectionFrequentlyAsked.faqDetails6Item1" />
                  </li>
                  <li>
                    <FormattedMessage id="SectionFrequentlyAsked.faqDetails6Item2" />
                  </li>
                  <li>
                    <FormattedMessage id="SectionFrequentlyAsked.faqDetails6Item3" />
                  </li>
                  <li>
                    <FormattedMessage id="SectionFrequentlyAsked.faqDetails6Item4" />
                  </li>
                </ul>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <div className={css.faqItemCount}>07</div>{' '}
                  <FormattedMessage id="SectionFrequentlyAsked.faqTitle7" />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <FormattedMessage id="SectionFrequentlyAsked.faqDetails7" />
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <div className={css.faqItemCount}>08</div>{' '}
                  <FormattedMessage id="SectionFrequentlyAsked.faqTitle8" />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <FormattedMessage id="SectionFrequentlyAsked.faqDetails8" />
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default SectionFrequentlyAsked;
