import React from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field from '../../Field';

import css from './SectionContainer.module.css';
import SectionFrequentlyAsked from './SectionFrequentlyAsked';

// This component can be used to wrap some common styles and features of Section-level components.
// E.g: const SectionHero = props => (<SectionContainer><H1>Hello World!</H1></SectionContainer>);
const SectionContainer = props => {
  const { className, rootClassName, id, as, children, appearance, options, ...otherProps } = props;
  const Tag = as || 'section';
  const classes = classNames(
    rootClassName || css.root,
    className,
    id == 'hero' && css.hero,
    id == 'consultants' && css.consultants_section,
    id == 'scoping_project' && css.scoping_project_div,
    id == 'receive_proposals' && css.receive_proposals_main_div,
    id == 'it_skills' && css.it_skills_main,
    id == 'should_join' && css.should_join_main,
    id == 'choose_taxable' && css.choose_taxable_main,
    id == 'get_in_touch' && css.get_in_touch_main,
    id == 'business_cases' && css.business_cases_main,
    id == 'recruitment_section' && css.recruitment_section_main
  );

  return (
    <>
      <Tag className={classes} id={id} {...otherProps}>
        {appearance?.fieldType === 'customAppearance' && id !== 'hero' ? (
          <Field
            data={{ alt: `Background image for ${id}`, ...appearance }}
            className={className}
            options={options}
            id={id}
       
          />
        ) : null}

        <div className={css.sectionContent}>{children}</div>
      </Tag>
      {id == 'get_in_touch' && <div>
        <SectionFrequentlyAsked />
      </div>}
    </>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionContainer.defaultProps = {
  rootClassName: null,
  className: null,
  as: 'div',
  children: null,
  appearance: null,
};

SectionContainer.propTypes = {
  rootClassName: string,
  className: string,
  as: string,
  children: node,
  appearance: object,
  options: propTypeOption,
};

export default SectionContainer;
