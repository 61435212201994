import React, { useState } from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockContainer from '../BlockContainer';
import TawkTo from './Tawk';
import Calendly from './Calendly';
import TalentCard from '../../../../components/TalentCard/TalentCard';
import { Button, IconCollection, NamedLink, OutsideClickHandler } from '../../../../components';
import { FormattedMessage } from 'react-intl';
import { getUserRole } from '../../../../util/dataExtractor';
import { BOOK_MODAL, CHAT_MODAL, USER_ROLE_CLIENT } from '../../../../util/types';
import ExptProfile from '../../../../assets/globalBanner.png';

import css from './BlockDefault.module.css';
import { useSelector } from 'react-redux';

const FieldMedia = props => {
  const { className, media, sizes, options, blockId } = props;
  const hasMediaField = hasDataInFields([media], options);
  return hasMediaField ? (
    <div className={classNames(className, css.media, blockId == 'hero-img' && css.hero_img)}>
      <Field data={media} sizes={sizes} options={options} />
    </div>
  ) : null;
};

const BlockDefault = props => {
  const {
    blockId,
    className,
    rootClassName,
    mediaClassName,
    textClassName,
    ctaButtonClass,
    title,
    text,
    callToAction,
    media,
    responsiveImageSizes,
    options,
    id,
  } = props;
  const state = useSelector(state => state);
  const { currentUser = {} } = state?.user || {};

  const { isAuthenticated = false } = state?.auth || {};

  const classes = classNames(
    rootClassName || css.root,
    className,
    blockId == 'hero-block' && css.heroBlock,
    blockId == 'privacy-policy' && css.privacyPolicy,
    id == 'consultants' && css.consultants_card,
    blockId == 'scoping_text' && css.scoping_text_div,
    (blockId == 'choose_taxable_card_01' ||
      blockId == 'choose_taxable_card_02' ||
      blockId == 'choose_taxable_card_03' ||
      blockId == 'choose_taxable_card_04' ||
      blockId == 'choose_taxable_card_05' ||
      blockId == 'choose_taxable_card_06') &&
    css.choose_taxable_card,
    (blockId == 'business_cases_01' ||
      blockId == 'business_cases_02' ||
      blockId == 'business_cases_03') &&
    css.business_cases_card,
    (blockId == 'recruitment_consultant' || blockId == 'recruitment_client') &&
    css.recruitment_card,
    (blockId == 'talent_network_card_01' ||
      blockId == 'talent_network_card_02' ||
      blockId == 'talent_network_card_03' ||
      blockId == 'talent_network_card_04') &&
    css.talent_network_card
  );
  const hasTextComponentFields = hasDataInFields([title, text, callToAction], options);

  const [openModal, setOpenModal] = useState(null);
  function handleButtonClick() {
    if (openModal === CHAT_MODAL) {
      setOpenModal(null);
    } else {
      setOpenModal(CHAT_MODAL);
    }
  }


  return (
    <BlockContainer id={blockId} className={classes}>
      {blockId == 'business_cases_01' ||
        blockId == 'business_cases_02' ||
        blockId == 'business_cases_03' ? (
        <div className={css.business_cases_card_main_div}>
          <FieldMedia
            media={media}
            sizes={responsiveImageSizes}
            className={classNames(
              mediaClassName,
              css.business_cases_card_media
            )}
            options={options}
            blockId={blockId}
          />
          <div className="">
            {blockId == 'business_cases_01' && (
              <>
                <div className={css.business_cases_card_img_name}>Nicole</div>
              </>
            )}
            {blockId == 'business_cases_02' && (
              <>
                <div className={css.business_cases_card_img_name}>Denise</div>
              </>
            )}
            {blockId == 'business_cases_03' && (
              <>
                {' '}
                <div className={css.business_cases_card_img_name}>Bart</div>{' '}
              </>
            )}
          </div>
        </div>
      ) : blockId == 'it_skills_img' ?
        <div className={classNames(
          mediaClassName,
        )}>
          <img src={ExptProfile} alt="global network" />
        </div>
        : (
          <FieldMedia
            media={media}
            sizes={responsiveImageSizes}
            className={classNames(
              mediaClassName,
              (blockId == 'companies_img_01' ||
                blockId == 'companies_img_02' ||
                blockId == 'companies_img_03' ||
                blockId == 'companies_img_04' ||
                blockId == 'companies_img_05' ||
                blockId == 'companies_img_06' ||
                blockId == 'companies_img_07') &&
              css.companies,
              blockId == 'scoping_project' && css.scoping_project_div,
              blockId == 'recruitment_consultant' && css.recruitment_card
            )}
            options={options}
            blockId={blockId}
          />
        )}

      {hasTextComponentFields ? (
        <div className={classNames(textClassName, css.text)}>
          {blockId == 'hero-block' ? (
            <div className={css.hero_title}>
              {' '}
              <Field data={title} options={options} />
            </div>
          ) : (
            <>
              {id == 'talent_network' ? null : <Field data={title} options={options} />}

              {blockId == 'it_skills_text' && (
                <div className={css.it_skills_img_mobile_res}>
                  <img
                    src={ExptProfile}
                    alt="ExptProfile"
                  />
                </div>
              )}
            </>
          )}

          {blockId == 'hero-block' ? (
            <div className={css.hero_p}>
              {' '}
              <Field data={text} options={options} />
            </div>
          ) : (
            <>

              {id == 'talent_network' ? null : <Field data={text} options={options} />}
              {blockId == 'it_skills_text' && (
                <div className={css.it_skills_button}>
                  <NamedLink name="SignupPage" className={isAuthenticated ? classNames(css.link, css.pointerEvents) : css.projectSubmitLink} to={{ client: true }}>
                    <Button className={css.Consultbtn}>
                      <IconCollection name="ICON_SEARCH" />{' '}
                      <FormattedMessage id="SectionMeetGlobalNetwork.buttonText" />
                    </Button>
                  </NamedLink>
                </div>
              )}
              {blockId == 'business_cases_01' && (
                <div className={css.business_cases_card_teg}>
                  <div className={css.business_cases_card_teg_text}>
                    <h5>6+2 Months</h5>
                    <p>Project Time</p>
                  </div>
                  <div className={css.business_cases_card_teg_text}>
                    <h5>Place of Work</h5>
                    <p>Germany & Remote</p>
                  </div>
                  <div className={css.business_cases_card_teg_link}>
                    <NamedLink name={isAuthenticated ? "NewListingPage" : "SignupPage"} className={getUserRole(currentUser) !== USER_ROLE_CLIENT && isAuthenticated ? classNames(css.link, css.pointerEvents) : css.projectSubmitLink} to={{ client: true }}>
                      <h4>Start similar project</h4>
                    </NamedLink>
                  </div>
                </div>
              )}
              {blockId == 'business_cases_02' && (
                <div className={css.business_cases_card_teg}>
                  <div className={css.business_cases_card_teg_text}>
                    <h5>8 Months</h5>
                    <p>Project Time</p>
                  </div>
                  <div className={css.business_cases_card_teg_text}>
                    <h5>Place of Work</h5>
                    <p>Fully Remote</p>
                  </div>
                  <div className={css.business_cases_card_teg_link}>
                    <NamedLink name={isAuthenticated ? "NewListingPage" : "SignupPage"} className={getUserRole(currentUser) !== USER_ROLE_CLIENT && isAuthenticated ? classNames(css.link, css.pointerEvents) : css.projectSubmitLink} to={{ client: true }}>
                      <h4>Start similar project</h4>
                    </NamedLink>
                  </div>
                </div>
              )}
              {blockId == 'business_cases_03' && (
                <div className={css.business_cases_card_teg}>
                  <div className={css.business_cases_card_teg_text}>
                    <h5>12 Months</h5>
                    <p>Project Time</p>
                  </div>
                  <div className={css.business_cases_card_teg_text}>
                    <h5>Place of Work</h5>
                    <p>UK & Remote</p>
                  </div>
                  <div className={css.business_cases_card_teg_link}>
                    <NamedLink name={isAuthenticated ? "NewListingPage" : "SignupPage"} className={getUserRole(currentUser) !== USER_ROLE_CLIENT && isAuthenticated ? classNames(css.link, css.pointerEvents) : css.projectSubmitLink} to={{ client: true }}>
                      <h4>Start similar project</h4>
                    </NamedLink>
                  </div>
                </div>
              )}

              {blockId == 'recruitment_client' && (
                <div>
                  <ul>
                    <li>
                      <div className={css.recruitment_consultant_card_num}>01</div>
                      <span>Submit your project via the scoping module</span>
                    </li>
                    <li>
                      <div className={css.recruitment_consultant_card_num}>02</div>
                      <span>Get matched to vetted consultants in no time</span>
                    </li>
                    <li>
                      <div className={css.recruitment_consultant_card_num}>03</div>
                      <span>Chat, interview and receive proposals</span>
                    </li>
                    <li>
                      <div className={css.recruitment_consultant_card_num}>04</div>
                      <span>Start your project together</span>
                    </li>
                  </ul>
                </div>
              )}
              {blockId == 'recruitment_consultant' && (
                <div>
                  <ul>
                    <li>
                      <div className={css.recruitment_consultant_card_num}>01</div>
                      <span>Create your professional profile and get verified</span>
                    </li>
                    <li>
                      <div className={css.recruitment_consultant_card_num}>02</div>
                      <span>Get invited to exciting new projects</span>
                    </li>
                    <li>
                      <div className={css.recruitment_consultant_card_num}>03</div>
                      <span>Chat and send your proposal</span>
                    </li>
                    <li>
                      <div className={css.recruitment_consultant_card_num}>04</div>
                      <span>Complete your project and get paid</span>
                    </li>
                  </ul>
                </div>
              )}

              {blockId == 'talent_network_card_01' && (
                <div className={css.ProductCard_cardMainContent}>
                  <div className={css.ProductCard_cardHeader}>
                    <div className={css.ProductCard_nameWrapper}>
                      <Field data={title} options={options} />
                      <Field data={text} options={options} />
                    </div>
                    <div className={css.ProductCard_videoIconWrapper}>
                      <IconCollection name="ICON_VIDEO" />
                    </div>
                  </div>
                  <div className={css.ProductCard_projectCategory}>
                    <h6>Project Expertise</h6>
                    <ul className={css.ProductCard_projectCategories}>
                      <li>
                        <span>Corporate Tax Advisory</span>
                      </li>
                      <li>
                        <span>Tax transformation project</span>
                      </li>
                      <li>
                        <span>International Tax Planning</span>
                      </li>
                    </ul>
                  </div>
                  <div className={css.ProductCard_projectCategory}>
                    <h6>Software knowledge </h6>
                    <ul className={css.ProductCard_projectCategories}>
                      <li>
                        <span>SAP S4/hana</span>
                      </li>
                      <li>
                        <span>Oracle R12</span>
                      </li>
                      <li>
                        <span>Microsoft Dynamics</span>
                      </li>
                    </ul>
                  </div>
                  <div className={css.ProductCard_projectCategory}>
                    <h6>Place of Work</h6>
                    <ul className={css.ProductCard_projectCategories}>
                      <li>
                        <span>United Kingdom &amp; remote</span>
                      </li>
                    </ul>
                  </div>
                  <NamedLink name="SignupPage" className={isAuthenticated ? classNames(css.link, css.pointerEvents) : css.projectSubmitLink} to={{ client: true }}>
                    <Button className={css.actionBtn}>Start project request</Button>
                  </NamedLink>
                </div>
              )}
              {blockId == 'talent_network_card_02' && (
                <div className={css.ProductCard_cardMainContent}>
                  <div className={css.ProductCard_cardHeader}>
                    <div className={css.ProductCard_nameWrapper}>
                      <Field data={title} options={options} />
                      <Field data={text} options={options} />
                    </div>
                    <div className={css.ProductCard_videoIconWrapper}>
                      <IconCollection name="ICON_VIDEO" />
                    </div>
                  </div>
                  <div className={css.ProductCard_projectCategory}>
                    <h6>Project Expertise</h6>

                    <ul className={css.ProductCard_projectCategories}>
                      <li>
                        <span>Tax Compliance</span>
                      </li>
                      <li>
                        <span>Transfer Pricing management</span>
                      </li>
                      <li>
                        <span>Tax Automation project</span>
                      </li>
                    </ul>
                  </div>
                  <div className={css.ProductCard_projectCategory}>
                    <h6>Software knowledge </h6>

                    <ul className={css.ProductCard_projectCategories}>
                      <li>
                        <span>SAP Ariba</span>
                      </li>
                      <li>
                        <span>JDEdwards</span>
                      </li>
                      <li>
                        <span>Microsoft Dynamics</span>
                      </li>
                    </ul>
                  </div>
                  <div className={css.ProductCard_projectCategory}>
                    <h6>Place of Work</h6>

                    <ul className={css.ProductCard_projectCategories}>
                      <li>
                        <span>Fully remote</span>
                      </li>
                    </ul>
                  </div>
                  <NamedLink name="SignupPage" className={isAuthenticated ? classNames(css.link, css.pointerEvents) : css.projectSubmitLink} to={{ client: true }}>
                    <Button className={css.actionBtn}>Start project request</Button>
                  </NamedLink>
                </div>
              )}
              {blockId == 'talent_network_card_03' && (
                <div className={css.ProductCard_cardMainContent}>
                  <div className={css.ProductCard_cardHeader}>
                    <div className={css.ProductCard_nameWrapper}>
                      <Field data={title} options={options} />
                      <Field data={text} options={options} />
                    </div>
                    <div className={css.ProductCard_videoIconWrapper}>
                      <IconCollection name="ICON_VIDEO" />
                    </div>
                  </div>
                  <div className={css.ProductCard_projectCategory}>
                    <h6>Project Expertise</h6>

                    <ul className={css.ProductCard_projectCategories}>
                      <li>
                        <span>E-reporting &amp; E-invoicing</span>
                      </li>
                      <li>
                        <span>ERP VAT implementation</span>
                      </li>
                      <li>
                        <span>EU VAT compliance</span>
                      </li>
                    </ul>
                  </div>
                  <div className={css.ProductCard_projectCategory}>
                    <h6> Software knowledge</h6>

                    <ul className={css.ProductCard_projectCategories}>
                      <li>
                        <span>OneSource</span>
                      </li>
                      <li>
                        <span>Vertex</span>
                      </li>
                      <li>
                        <span>Avalara</span>
                      </li>
                      <li>
                        <span>SAP ECC</span>
                      </li>
                    </ul>
                  </div>
                  <div className={css.ProductCard_projectCategory}>
                    <h6>Place of Work</h6>

                    <ul className={css.ProductCard_projectCategories}>
                      <li>
                        <span>Luxembourg</span>
                      </li>
                      <li>
                        <span>United Kingdom &amp; remote</span>
                      </li>
                    </ul>
                  </div>
                  <NamedLink name="SignupPage" className={isAuthenticated ? classNames(css.link, css.pointerEvents) : css.projectSubmitLink} to={{ client: true }}>
                    <Button className={css.actionBtn}>Start project request</Button>
                  </NamedLink>
                </div>
              )}
              {blockId == 'talent_network_card_04' && (
                <div className={css.ProductCard_cardMainContent}>
                  <div className={css.ProductCard_cardHeader}>
                    <div className={css.ProductCard_nameWrapper}>
                      <Field data={title} options={options} />
                      <Field data={text} options={options} />
                    </div>
                    <div className={css.ProductCard_videoIconWrapper}>
                      <IconCollection name="ICON_VIDEO" />
                    </div>
                  </div>
                  <div className={css.ProductCard_projectCategory}>
                    <h6>Project Expertise</h6>

                    <ul className={css.ProductCard_projectCategories}>
                      <li>
                        <span>Import/export declarations</span>
                      </li>
                      <li>
                        <span>customs software implementation</span>
                      </li>
                    </ul>
                  </div>
                  <div className={css.ProductCard_projectCategory}>
                    <h6>Software knowledge </h6>

                    <ul className={css.ProductCard_projectCategories}>
                      <li>
                        <span>Descartes</span>
                      </li>
                      <li>
                        <span>Portbase</span>
                      </li>
                      <li>
                        <span>Gateway</span>
                      </li>
                      <li>
                        <span>Cargowise</span>
                      </li>
                    </ul>
                  </div>
                  <div className={css.ProductCard_projectCategory}>
                    <h6>Place of Work</h6>
                    <ul className={css.ProductCard_projectCategories}>
                      <li>
                        <span>Netherlands &amp; remote</span>
                      </li>
                    </ul>
                  </div>
                  <NamedLink name="SignupPage" className={isAuthenticated ? classNames(css.link, css.pointerEvents) : css.projectSubmitLink} to={{ client: true }}>
                    <Button className={css.actionBtn}>Start project request</Button>
                  </NamedLink>
                </div>
              )}
            </>
          )}

          {blockId == 'hero-block' ? !isAuthenticated &&
            <div className={css.buttonGroups}>
              <NamedLink name="SignupPage" className={css.projectSubmitLink} to={{ client: true }}>
                <FormattedMessage id="SectionHero.clientSignupButton" />
                <IconCollection name="ICON_ARROW_RIGHT" />
              </NamedLink>
              <NamedLink name="SignupPage" className={css.signupLink}>
                <FormattedMessage id="SectionHero.signupButton" />
              </NamedLink>
            </div>
            :
            blockId == "scoping_text" ?
              <>
                <NamedLink name={isAuthenticated ? "NewListingPage" : "SignupPage"} className={getUserRole(currentUser) !== USER_ROLE_CLIENT && isAuthenticated ? classNames(css.link, css.pointerEvents) : css.projectSubmitLink} to={{ client: true }}>
                  <Button><FormattedMessage id="ScopingYourProject.startScoping" /></Button>
                </NamedLink>
              </>
              :
              blockId == "should_join_btn" ?
                <>
                  <NamedLink name="SignupPage" className={isAuthenticated ? classNames(css.link, css.pointerEvents) : css.projectSubmitLink} >
                    <Button><FormattedMessage id="SectionAmazingTeams.signupText" /></Button>
                  </NamedLink>
                </>
                : blockId == "get_in_touch_text" ?
                  <>
                    <Button className={css.chatBtn} onClick={() => handleButtonClick(CHAT_MODAL)}>
                      <FormattedMessage id="SectionGetInTouch.chatButton" />
                    </Button>
                    {openModal === CHAT_MODAL ? (
                      <TawkTo widgetId={process.env.REACT_APP_TAWK_PROPERTY_AND_WIDGET_ID} />
                    ) : openModal === BOOK_MODAL ? (
                      <OutsideClickHandler onOutsideClick={() => setOpenModal(null)}>
                        <Calendly />
                      </OutsideClickHandler>
                    ) : null}
                  </> :
                  <Field data={callToAction} className={ctaButtonClass} options={options} />
          }
        </div>
      ) : null}
    </BlockContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

BlockDefault.defaultProps = {
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
  title: null,
  text: null,
  callToAction: null,
  media: null,
  responsiveImageSizes: null,
  options: null,
};

BlockDefault.propTypes = {
  blockId: string.isRequired,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
  title: object,
  text: object,
  callToAction: object,
  media: object,
  responsiveImageSizes: string,
  options: propTypeOption,
};

export default BlockDefault;
